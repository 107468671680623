import {useStaticQuery, graphql} from "gatsby";

export const useSiteContent = () => {
  const { siteContent } = useStaticQuery(graphql`
        query SiteContentData {
            siteContent {
                id
                areas_esp {
                    areas
                }
                contact {
                    lat
                    lng
                }
                cover {
                    subtitle
                    title
                }
                empresa {
                    description
                    label_1
                    label_2
                    label_3
                }
                footer {
                    lista_telefonos
                    map_url
                    address
                }
                mission_vision {
                    mission
                    vision
                }
                navbar {
                    social_links {
                        instagram
                        fb
                        linkedin
                        twitter
                    }
                }
                services {
                    bank
                    briefcase
                    businessman
                    contract
                    copy
                }
                we_are_part {
                    afisofom_desc
                    afiservicios_desc
                    mlp_desc
                }
                not_found {
                    text
                    _404_img
                    _404_img_responsive
                }
            }
        }

    `
  );

  return siteContent;
}
